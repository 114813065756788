@import url("https://fonts.googleapis.com/css?family=Lato:regular,bold,black,italic&subset=latin,latin-ext");

.shadow {
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.27);
}

body,
textarea {
  font-family: "Lato";
}

.codeEntryBox {
  border-width: 0;
  text-align: center;
  width: 100%;
  font-family: "Lato";
  font-weight: 800;
  font-size: 28px;
}

.codeEntryBox:placeholder-shown {
  color: "#555555";
  opacity: 39%;
}
.codeEntryBox:focus {
  border-width: 0;
}
textarea:focus {
  border-width: 0;
}

.notesBox {
  min-height: 200px;
  flex: 1;
  border-width: 0;
  font-family: "Lato";
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.notesBox:focus {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

.tweak {
  transform: rotate(2.5deg);
}

.drop_area {
  /* backgroundColor: "white",
  justifyContent: "center",
  display: "flex",
  height: "50%",
  width: "50%",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: 16, */
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50%;
  width: 50%;
  align-items: center;
  flex-direction: column;
  border-radius: 16;
  padding: 32px;
}
